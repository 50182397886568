import React, { useEffect, useRef, useState } from 'react';
import { Grid, useMediaQuery, Typography, Box, Button, Card, TextField, Tooltip, IconButton, ClickAwayListener, InputAdornment, Popper, Paper, Grow } from '@mui/material';
import { Icon } from '@iconify/react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { TextGray, White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import LogoGoogle from '../../assets/Logo/Google_Logo.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUserPersonalInformation, sendEmailResetPassword, userLogin, verifyOTP } from '../../components/Config/Api';
import SuccessAlert from '../../components/ToastyAlert/SuccessAlert';
import Top from '../../components/Register/Top/Top';
import Footer from '../../components/Register/Footer/Footer';
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert';
import Notification from '../../components/Modal/Notification/Notification';
import InfoAlert from '../../components/ToastyAlert/InfoAlert';

const ForgetPassword = () => {

    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    const timerId = useRef()

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingLoginPage, setIsLoadingLoginPage] = useState(false)
    const [isLoadingKirimOTP, setIsLoadingKirimOTP] = useState(false)
    const [newData, setNewData] = useState({
        email: '',
        kodeOtp: ''
    })
    const [showPassword, setShowPassword] = useState(false);
    const [statusEmail, setStatusEmail] = useState(false);
    const [openNotificationModal, setOpenNotificationModal] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [countdownTimer, setCountdownTimer] = useState(0)
    const [dataVerifyOTP, setDataVerifyOTP] = useState(null)
    const [switchIcon, setSwitchIcon] = useState('')
    const [message, setMessage] = useState('')
    const [judul, setJudul] = useState('')


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        timerId.current = setInterval(() => {
            if (countdownTimer >= 0) {
                setCountdownTimer(prev => prev - 1)
            }
        }, 1000)
        return () => clearInterval(timerId.current)
    }, [countdownTimer])

    const handleClickLoginPage = () => {
        setIsLoadingLoginPage(true)
        setTimeout(() => {
            setIsLoadingLoginPage(false)
            navigate('/login')
        }, 1500);
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (dataVerifyOTP.email === newData.email) {
            await axios.put(`${verifyOTP}/${dataVerifyOTP.verfication_password_id}`, {
                verification_link: dataVerifyOTP.verification_link,
            }).then((response) => {
                // console.log(response.data);
                if (response.data?.data?.verification_link === newData.kodeOtp) {
                    SuccessAlert({
                        message: 'Berhasil vefikasi OTP',
                        position: 'top-center'
                    })
                    // Simpan data ke dalam localStorage
                    localStorage.setItem('rp', JSON.stringify({
                        verfication_password_id: response.data?.data.verfication_password_id
                    }));
                    setTimeout(() => {
                        navigate('/reset-password')
                        setIsLoading(false)
                    }, 3000);
                } else {
                    ErrorAlert({
                        message: 'Kode OTP salah',
                        position: 'top-center'
                    })
                    setIsLoading(false)
                }
            }).catch((error) => {
                console.log(error.response);
                setIsLoading(false)
            })
        } else {
            ErrorAlert({
                message: 'Email tidak sesuai, silahkan kirim kode OTP lagi',
                position: 'top-center'
            })
            setIsLoading(false)
        }

    }

    const handleSendEmail = async () => {
        setIsLoadingKirimOTP(true)

        await axios.post(sendEmailResetPassword, {
            email: newData.email
        }).then((response) => {
            // console.log('sendEmailResetPassword', response.data);
            setMessage('Kami telah mengirim kode verifikasi ke email Anda, silahkan cek inbox atau spambox. Kode OTP akan kadaluarsa setelah 24 jam.')
            setJudul('Berhasil mengirim OTP')
            setSwitchIcon('email')
            setDataVerifyOTP(response.data?.data)
            setCountdownTimer(60)
            setStatusEmail(true)
            setOpenNotificationModal(true)
            setIsLoadingKirimOTP(false)
            // localStorage.setItem(JSON.stringify({
            //     email: 
            // }))
        }).catch((error) => {
            console.log(error.response);
            setIsLoadingKirimOTP(false)
            ErrorAlert({
                message: error.response?.data?.message === "User not found" ? 'Email belum terdaftar'
                    :
                    error.response?.data?.message === "Email already sent" ? 'Kode OTP sudah terkirim ke email Anda'
                        :
                        error.response?.data?.message === "OTP active period is 24 hours after the email is sent" ? undefined
                            :
                            error.response?.data?.message,
                position: 'top-center',
            })
            if (error.response?.data?.message === "OTP active period is 24 hours after the email is sent") {
                setJudul('Gagal Mengirim OTP')
                setMessage('Gunakan kode OTP yang sebelumnya sudah terkirim ke email Anda, silahkan cek inbox atau spambox. Kode OTP akan kadaluarsa setelah 24 jam.')
                setSwitchIcon('failedsendemail')
                setOpenNotificationModal(true)
            }
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });

        if (value !== '' && name === 'email' && !validateEmail(value)) {
            setEmailError('Format Email Salah');
        } else {
            setEmailError('');
        }

    };


    return (
        <>
            <Top />
            <Grid
                container
                sx={{
                    backgroundColor: bgColor,
                    padding: isMobile ? '7px 15px' : isTablet ? '7px 20px' : '7px 83px',
                    height: 'auto', // Change height to auto
                    minHeight: 'calc(100vh - 300px)', // Ensure content takes at least the full viewport height minus header/footer
                    overflow: 'hidden',
                    // backgroundColor:' orange',
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: isMobile ? '8%' : '10%', // Adjust height based on screen size
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: isMobile ? '13px' : isTablet ? '13px' : '23px',
                        // backgroundColor:' orange'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // gap: '10px'
                        }}
                    >
                        <Button
                            onClick={() => {
                                window.location.href = 'https://beasiswapapuatengah.id'
                            }}
                            sx={{
                                textTransform: 'none',
                                color: 'black',
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: bgColor
                                },
                            }}
                        >
                            <Icon icon='material-symbols:arrow-back' />
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '13px',
                                    fontWeight: '400',
                                    lineHeight: '20.16px',
                                    cursor: 'pointer',
                                    marginLeft: '10px'
                                }}
                            >
                                Kembali ke Beranda
                            </Typography>
                        </Button>
                    </Box>
                    <Button
                        onClick={handleClickLoginPage}
                        variant='contained'
                        size='small'
                        sx={{
                            minWidth: '150px',
                            backgroundColor: btnBgColor,
                            color: mainColor,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            padding: isMobile ? '0x 10px' : undefined,
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor
                            },
                        }}
                    >
                        {isLoadingLoginPage ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px', color: White }} />
                            :
                            'Masuk Akun'
                        }
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        padding: isMobile ? '15px' : undefined,
                    }}
                >
                    <form onSubmit={handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '58px',
                            width: '100%',
                            //    backgroundColor:'orange'
                        }}
                    >
                        <Paper
                            elevation={2}
                            sx={{
                                maxWidth: '500px',
                                width: '100%',
                                marginBottom: isMobile ? '27px' : '50px',
                                padding: '20px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '22px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    Lupa Kata Sandi
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // maxWidth: '400px',
                                    // backgroundColor: 'orange',
                                    width: isMobile ? '100%' : isTablet ? '50%' : '30%',
                                    width: '100%',
                                    marginTop: '30px',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    type="text"
                                    label="Email"
                                    placeholder='Masukkan email yang terdaftar'
                                    value={newData.email}
                                    name="email"
                                    onChange={(e) => handleChange(e)}
                                    error={newData.email === '' ? undefined : !!emailError}
                                    helperText={emailError}
                                    autoComplete="off"
                                    required
                                    // disabled={statusEmail ? true : false}
                                    InputProps={{
                                        endAdornment: newData.email && validateEmail(newData.email) ?
                                            (
                                                <InputAdornment position="end">
                                                    <Button
                                                        onClick={() => handleSendEmail()}
                                                        sx={{
                                                            // padding: '0px',
                                                            margin: '0px',
                                                            textTransform: 'none',
                                                            color: mainColor,
                                                            // font-family: Plus Jakarta Sans;
                                                            fontSize: '13px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: 'normal',
                                                            borderRadius: '0px',
                                                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                                "& fieldset": {
                                                                    borderBottomColor: mainColor,
                                                                },
                                                            },
                                                            "& .MuiInputLabel-root.Mui-disabled": {
                                                                color: mainColor,
                                                            },
                                                        }}
                                                        disabled={isLoadingKirimOTP || countdownTimer > 0 ? true : false}
                                                    >
                                                        {isLoadingKirimOTP ?
                                                            <Icon icon='svg-spinners:3-dots-move' style={{ fontSize: '20px' }} />
                                                            :
                                                            countdownTimer > 0 ?
                                                                `Dapat mengirim ${countdownTimer}`
                                                                :
                                                                'Kirim OTP'
                                                        }
                                                    </Button>
                                                </InputAdornment>
                                            )
                                            :
                                            undefined
                                    }}
                                    sx={{
                                        marginBottom: isMobile ? '24px' : '30px',
                                        fontSize: isMobile ? '14px' : '13px',
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            // WebkitTextFillColor: "white",
                                        },
                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                            "& fieldset": {
                                                borderBottomColor: mainColor,
                                            },
                                        },
                                        "& .MuiInputLabel-root.Mui-disabled": {
                                            color: statusEmail ? TextGray : mainColor,
                                        },
                                        "& .MuiFilledInput-underline:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: '#B3B3D4',
                                            fontWeight: 'bold',
                                            fontSize: '13px'
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: mainColor,
                                        },
                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                            color: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:hover:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:hover:after": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus:after": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                        {
                                            "-webkit-appearance": "none",
                                            margin: 0,
                                        },
                                        "& input[type=number]": {
                                            "-moz-appearance": "textfield",
                                        },
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    type="text"
                                    label="Kode OTP"
                                    placeholder='Masukkan Kode OTP'
                                    value={newData.kodeOtp}
                                    name="kodeOtp"
                                    onChange={(e) => handleChange(e)}
                                    autoComplete="off"
                                    onInput={(e) => {
                                        // Filter out non-numeric characters
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                    required
                                    disabled={!statusEmail ? true : false}
                                    sx={{
                                        marginBottom: isMobile ? '24px' : '34px',
                                        fontSize: isMobile ? '14px' : '13px',
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            // WebkitTextFillColor: "white",
                                        },
                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                            "& fieldset": {
                                                borderBottomColor: mainColor,
                                            },
                                        },
                                        "& .MuiInputLabel-root.Mui-disabled": {
                                            color: !statusEmail ? TextGray : mainColor,
                                        },
                                        "& .MuiFilledInput-underline:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: '#B3B3D4',
                                            fontWeight: 'bold',
                                            fontSize: '13px'
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: mainColor,
                                        },
                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                            color: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:hover:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:hover:after": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus:before": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& .MuiFilledInput-underline:focus:after": {
                                            borderBottomColor: mainColor,
                                        },
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                        {
                                            "-webkit-appearance": "none",
                                            margin: 0,
                                        },
                                        "& input[type=number]": {
                                            "-moz-appearance": "textfield",
                                        },
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: '14px',
                                    // marginBottom: 'px',
                                }}
                            >
                                <Button
                                    fullWidth
                                    variant='contained'
                                    size='medium'
                                    type='submit'
                                    // onClick={() => handleSubmit()}
                                    sx={{
                                        fontSize: isMobile ? '12px' : undefined,
                                        backgroundColor: btnBgColor,
                                        color: mainColor,
                                        padding: '5px 0px',
                                        textTransform: 'none',
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            color: 'white', // Change text color to white on hover
                                            backgroundColor: mainColor,
                                            // fontWeight:'bold',
                                        },
                                    }}
                                    disabled={isLoading || !statusEmail ? true : false}
                                >
                                    {isLoading ?
                                        <Icon icon='svg-spinners:tadpole' style={{ fontSize: isMobile ? '21px' : '24.5px' }} />
                                        :
                                        'Verifikasi Email'
                                    }
                                </Button>
                            </Box>
                        </Paper>
                    </form>
                </Grid>
            </Grid >
            <Footer />
            <Notification
                open={openNotificationModal}
                close={() => setOpenNotificationModal(false)}
                label={judul}
                message={message}
                chooseIcon={switchIcon}
            />
            <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
        </>
    )
}

export default ForgetPassword