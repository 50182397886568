import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    TextField,
    Typography,
    Modal,
    useMediaQuery,
    Button,
    Divider,
    TextareaAutosize,
    Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Dark, DividerColor, TextGray, btnBgColor, mainColor } from "../../Config/Color";
import axios from "axios";
import { RegistrasiTahapII_and_TahapIII, createTicket } from "../../Config/Api";
import SuccessAlert from '../../../components/ToastyAlert/SuccessAlert'
import ErrorAlert from '../../../components/ToastyAlert/ErrorAlert'
import { useFormContext } from "../../../pages/Registrasi/FormContext";

const Tahap1 = ({
    isMobile,
    isTablet,
    open,
    close,
    selectedImageNIKExist,
    selectedImageKKExist,
    selectedImageNIKUrlExist,
    selectedImageKKUrlExist,
    dataStep1Exist,
}) => {
    console.log('dataStep1Exist', dataStep1Exist);
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: '10px',
                    marginBottom: '20px'
                    // backgroundColor: 'orange'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: isMobile ? '40%' : '50%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Nama Lengkap
                    </Typography>
                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep1Exist.userFullNameExist ? dataStep1Exist.userFullNameExist : ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '50%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Email
                    </Typography>
                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep1Exist.userEmailExist ? dataStep1Exist.userEmailExist : ''}
                    </Typography>
                </Box>
            </Box>

            {/* Column 2 Tahap I */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-center',
                    gap: '10px',
                    marginBottom: '20px'
                    // backgroundColor: 'orange'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: isMobile ? '40%' : '50%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        NIK
                    </Typography>
                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep1Exist.userNIKExist ? dataStep1Exist.userNIKExist : ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: isMobile ? '40%' : '50%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Gambar/Scan Foto KTP
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedImageNIKExist && selectedImageNIKExist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedImageNIKExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedImageNIKExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
            </Box>

            {/* Column 3 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-center',
                    gap: '10px',
                    marginBottom: '20px'
                    // backgroundColor: 'orange'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: isMobile ? '40%' : '50%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        No KK
                    </Typography>
                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep1Exist.userNoKKExist ? dataStep1Exist.userNoKKExist : ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: isMobile ? '40%' : '50%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Gambar/Scan Foto KK
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedImageKKExist && selectedImageKKExist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedImageKKExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedImageKKExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
            </Box>

            {/* Column 4 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-center',
                    gap: '10px',
                    // marginBottom: '10px'
                    // backgroundColor: 'orange'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: isMobile ? '40%' : '50%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Nama Ayah
                    </Typography>
                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep1Exist.userFatherNameExist ? dataStep1Exist.userFatherNameExist : ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: isMobile ? '40%' : '50%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Nama Ibu
                    </Typography>
                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep1Exist.userMotherNameExist ? dataStep1Exist.userMotherNameExist : ''}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default Tahap1