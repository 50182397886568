// export const url = 'https://scholarship.parkways.id/'
// export const url = 'https://api.beasiswapapuatengah.id/'
export const url = 'https://apipapua.bct.co.id/'
// export const url = 'http://localhost:8004/'

// Register
export const SaveDataRegistrasiTahapI = `${url}api/register/personalinformation`
export const updateDataRegistrasiTahapI = `${url}api/register/updpersonalinformation`
export const RegistrasiTahapII_and_TahapIII = `${url}api/register/scholarshipapplication`
export const ExitingData = `${url}api/sup/getsupdn`
export const CekSupDN = `${url}api/sup/ceksupdn`
export const listDataUniversity = `${url}api/dashboarduser/readUniversity`
export const getDataListActiveCategory = `${url}api/dashboardadmin/showjadwalregister`
export const cekRegistrasiUser = `${url}api/cekregistrasiuser`
export const simpanDataTahap2 = `${url}api/register/savetahapii`
export const simpanDataTahap3 = `${url}api/register/savetahapiii`

// Verif Email
export const sendEmailVerifikasi = `${url}api/register/cekemail`
export const sendEmailVerifikasiSUP = `${url}api/sup/cekemail`
export const cekVerifikasiEmail = `${url}api/register/cekverifemail`
export const resendEmailApi = `${url}api/register/updverifemail`
export const activationEmail = `${url}api/register/verify-email`
export const activationEmailSUP = `${url}api/register/verify-email-sup`

// Ubah Password
export const updatePassword = `${url}api/user/updpass`

// Reset Password
export const sendEmailResetPassword = `${url}api/register/emailresetpassword`
export const verifyOTP = `${url}api/register/verify-email-password`
export const setNewPassword = `${url}api/register/resetpassword`

// Login
export const userLogin = `${url}api/user/login`
export const getUserPersonalInformation = `${url}api/dashboarduser/viewpersonalinformation`

// Session
export const deletepersonalinformation = `${url}api/register/delpersonalinformation`

//Dashboard Applicant
export const getDataDashboardApplicant = `${url}api/dashboarduser/completedata`
export const listSchedule = `${url}api/dashboardadmin/showjadwal`
export const getHistoryActivity = `${url}api/dashboarduser/showloguser`

// Halaman Data Pribadi
export const updateBiodata = `${url}api/dashboarduser/personalinformation`
export const listDataKabupaten = `${url}api/master/cekwilayahkab`
export const updateDataKTP = `${url}api/dashboarduser/personalinformationktp`
export const updateDataKK = `${url}api/dashboarduser/personalinformationkk`
export const updateDataKontakPribadi = `${url}api/dashboarduser/personalinformationcontact`

// Halaman Orangtua
export const updateDataBiodataOrangTua = `${url}api/dashboarduser/personalinformationparent`
export const updateDataBioPekerjaandataOrangTua = `${url}api/dashboarduser/personalinformationparentjob`
export const updateDataKontakOrangTua = `${url}api/dashboarduser/personalinformationparentcontact`

// Halaman Pendidikan
export const updateDataUniversitas = `${url}api/dashboarduser/academicinformation`
export const updateDataStudent = `${url}api/dashboarduser/academicistudentid`
export const updateDataKontakUniversitas = `${url}api/dashboarduser/academicunivcontact`
export const updateDataMasaStudi = `${url}api/dashboarduser/academicunivperiode`

// Halaman Pengaduan
export const createTicket = `${url}api/complaint/createticket`
export const sendMessage = `${url}api/complaint/chatmessage`
export const getListChat = `${url}api/complaint/showchat`
// export const getListTicket = `${url}api/complaint/showticket`
export const getListTicket = `${url}api/complaint/showticketuser`

// Halaman Berkas Persyaratan
export const dataAggrement = `${url}api/dashboarduser/aggrement`
export const updateDataPersyaratan = `${url}api/dashboarduser/updatepersyaratan`

//agreement
export const agreementQuestion = `${url}api/dashboardadmin/indexagreement`

//rekening
export const createInfoRekening = `${url}api/dashboarduser/createfinancial`
export const updateInfoRekening = `${url}api/dashboarduser/updatefinancial`
export const getDataRekening = `${url}api/dashboarduser/showfinancial`

// Halaman Riwayat
export const getDataRiwayat = `${url}api/dashboarduser/getscholarshippayments`

// Halaman Beasiswa SUP
export const updateDataBiayaPendidikan = `${url}api/dashboardsup/educationLiving`
export const updateDataAdmissionDocuments = `${url}api/dashboardsup/admissionDocuments`
export const updateDataAcademic = `${url}api/dashboardsup/dataAcademic`
export const updateDataPasspotrAndVisa = `${url}api/dashboardsup/passpotrAndVisa`



// endpoint untuk mendapatkan kode provinsi dan kabupaten papua tengah    
export const dataKodeWilayah91 = 'https://wilayah.id/api/regencies/91.json'
export const dataKodeWilayah94 = 'https://wilayah.id/api/regencies/94.json'



