import Footer from '../../components/Register/Footer/Footer';
import Top from '../../components/Register/Top/Top';
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useRef, useState } from 'react';
import { Typography, Box, Card, Grid, TextField, Autocomplete, InputAdornment, useMediaQuery, Button } from '@mui/material';
import { Icon } from '@iconify/react';
import BeasiswaBerprestasi from '../../components/Register/Category/BeasiswaBerprestasi';
import BeasiswaKeluargaMiskin from '../../components/Register/Category/BeasiswaKeluargaMiskin';
import { useFormContext } from './FormContext';
import { Dark, DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import Kuisioner from '../../components/Register/Category/Kuisioner';
import HandleSessionTahap2 from '../../components/Session/HandleSessionTahap2';
import axios from 'axios';
import { cekRegistrasiUser, deletepersonalinformation, getDataListActiveCategory, listDataUniversity } from '../../components/Config/Api';
import IkatanDinas from '../../components/Register/Category/IkatanDinas';
import { encrypt, decrypt } from 'n-krypta';
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert';
import { secretKey } from '../../components/Config/SecretKey';
import NotifTahapII from '../../components/Modal/NotifTahapII/NotifTahapII';
import { red } from '@mui/material/colors';
import LoadingData from '../../components/LoadingPage/LoadingData';
import NotifRefreshPage from '../../components/Modal/Notification/NotifRefreshPage';
import { useNavigate } from 'react-router-dom';
import CustomTooltip from '../../components/Modal/Tooltip/CustomTooltip';
import { DatePicker } from '@mui/x-date-pickers';

const RegistrasiTahap2 = () => {


    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    // const secretKey = '@BCTdev2024WebPapua'
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    const [activeCategory, setActiveCategory] = useState([]);
    const [selectedCategoryNew, setSelectedCategoryNew] = useState('Beasiswa Berprestasi');
    const [selectedJenjangPendidikanNew, setSelectedJenjangPendidikanNew] = useState('Strata 1(S1)');
    const [selectedCategory, setSelectedCategory] = useState('Beasiswa Berprestasi');
    const [selectedJenjangPendidikan, setSelectedJenjangPendidikan] = useState('Strata 1(S1)');
    const [openNotifModal, setOpenNotifModal] = useState(false)
    const [text, setText] = useState({
        label: '',
        message: ''
    })
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [OpenNotifRefreshPage, setOpenNotifRefreshPage] = useState(false);
    const [isDragging, setIsDragging] = useState({
        isDraggingNIM: false,
    });

    const [newData, setNewData] = useState({
        NIM: '',
        fakultas: '',
        programStudi: '',
        semesterSekarang: '',
    })
    const [listUniversity, setListUniversity] = useState([])

    const [selectedUniverity, setSelectedUniverity] = useState('');
    const [selectedUniverityNew, setSelectedUniverityNew] = useState('');
    const [monthAndYear, setMonthAndYear] = useState(null)
    const [dataPersonalInformation, setDataPersonalInformation] = useState('')

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const [selectedImageNIM, setSelectedImageNIM] = useState(null);
    const [imageUrlNIM, setImageUrlNIM] = useState(null);
    const fileInputRefNIM = useRef(null);



    const data = localStorage.getItem('data')



    const getDataCategory = async () => {
        const categories = new Set();
        await axios.get(getDataListActiveCategory)
            .then((response) => {
                // console.log('list category', response.data);
                const res = response.data;

                res.forEach(data => {
                    if (data.category !== "Siswa Unggul Papua") {
                        categories.add(JSON.stringify({ category: data.category, status: data.status }));
                    }
                });


                const data = Array.from(categories).map(item => JSON.parse(item))
                const currentActiveCategory = data.find(data => data.status === true);

                setActiveCategory(data)

                setSelectedCategory(currentActiveCategory ? currentActiveCategory.category : '')

                // console.log('cekActiveCategory', currentActiveCategory);
            }).catch((error) => {
                console.log(error.response);
                // if (error.code === 'ERR_NETWORK' || error.message.includes('ERR_CONNECTION_REFUSED')) {
                //     ErrorAlert({
                //         message: 'Tidak dapat terhubung ke server. Pastikan Anda terkoneksi ke internet.',
                //         position: 'top-center'
                //     })

                // }
                // if(data){
                //     const { dataStep2, cekData } = JSON.parse(data)
                //     if (cekData === 'Ya' && dataStep2) {
                //         getAlreadyExistData(dataStep2, cekData)
                //     }
                // }
            });
    };

    const handleClickMasukAkun = () => {
        setIsLoading(true)

        const item = localStorage.getItem('item');

        setTimeout(() => {
            setIsLoading(false)
            navigate('/login')
        }, 1500);
    }

    const getDataUniversity = async () => {
        await axios.get(listDataUniversity)
            .then((response) => {
                // console.log('data universitas', response.data);
                const data = response.data?.data;
                // Filter data untuk menghilangkan yang kosong, karakter aneh, dan entri whitespace
                const universities = data.map(item => item.nama_pt)
                    .filter(name => name && name.trim() && !name.includes('\\'));
                const uniqueUniversities = Array.from(new Set(universities)); // Menghapus duplikat universitas
                setListUniversity(uniqueUniversities);
            }).catch((error) => {
                console.log(error.response.data);
            });
    };

    const cekDataUser = async () => {
        const activationEmail = localStorage.getItem('ActivasiEmail')


        if (activationEmail) {
            const { email } = JSON.parse(activationEmail)
            const formData = new FormData();
            formData.append('personal_email', email);
            for (let pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }
            await axios.post(`${cekRegistrasiUser}`, formData)
                .then((response) => {
                    console.log('cekRegistrasiUser', response.data);
                    const { personalInformation } = response.data
                    // console.log(personalInformation);

                    setDataPersonalInformation(personalInformation.data)
                }).catch((error) => {
                    console.log(error.response);
                });
        }
    }
    
    useEffect(() => {
        const dataLogin = localStorage.getItem('LogInfo')

        if (dataLogin) {
            navigate('/dashboard-applicant')
        } else {
            setIsPageLoading(true)
            setTimeout(() => {
                getDataCategory()
                getDataUniversity()
                cekDataUser()
                window.scrollTo(0, 0);
                setIsPageLoading(false)
            }, 2500);
        }
    }, [])

    const handleCategoryClick = (item) => {
        setSelectedCategory(item.category);

        // if (item.status) {
        //     setSelectedCategory(item.category);
        // } else {
        //     ErrorAlert({
        //         message: `Pendaftaran ${item.category} belum dibuka`,
        //         position: 'top-center'
        //     })
        // }
    };

    const handleJenjangPendidikan = (jenjangPendidikan) => {
        setSelectedJenjangPendidikan(jenjangPendidikan);
    };

    const handleImageUpload = (fileInput) => {
        fileInput.current.click();
    };

    const handleRemoveImage = (removeImage) => {
        if (removeImage === 'nim') {
            setSelectedImageNIM(null);
            setImageUrlNIM(null);
        }
    };

    const handleDragOver = (event, option) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        if (option === 'nim') {
            setIsDragging({
                isDraggingNIM: true
            })
        }
    };

    const handleDrop = (event, option) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file, option);
        if (option === 'nim') {
            setIsDragging({
                isDraggingNIM: false
            })
        }
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingNIM: false,
        }) // Set state menjadi false saat dragleave
    };

    const handleFileInputChange = (file, fileInputChange) => {
        // const file = event.target.files[0];
        if (file) {
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto/Pdf 1 MB',
                    position: 'top-center'
                })
                // // Optionally, clear the file input
                // event.target.value = null;
                // return;
            } else {
                // Check the file type
                const fileType = file.type;
                const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
                const allowedPdfTypes = ['application/pdf'];

                if (allowedImageTypes.includes(fileType)) {
                    // Handle Image File
                    if (fileInputChange === 'nim') {
                        setSelectedImageNIM(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlNIM(imageUrl);
                    }
                } else if (allowedPdfTypes.includes(fileType)) {
                    // Handle PDF file
                    if (fileInputChange === 'nim') {
                        setSelectedImageNIM(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlNIM(imageUrl);
                    }
                } else {
                    // Unsupported file type
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png, gif dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }


        }
    };

    const handleShowPDF = (fileName) => {

        return (
            <>
                <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: mainColor,
                        fontSize: '11px',
                        fonStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginTop: '-10px'
                    }}
                >
                    {fileName}
                </Typography>
            </>
        )
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    };

    return (
        <>
            <Top />
            <Grid
                container
                sx={{
                    backgroundColor: bgColor,
                    padding: isMobile ? '7px 15px' : isTablet ? '7px 20px' : '7px 83px',
                    height: 'auto', // Change height to auto
                    overflow: 'hidden',
                    minHeight: 'calc(100vh - 300px)', // Ensure content takes at least the full viewport height minus header/footer
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: isMobile ? '8%' : '10%', // Adjust height based on screen size
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: isMobile ? '13px' : isTablet ? '13px' : '23px',
                        // backgroundColor:' orange'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // gap: '10px'
                        }}
                    >
                        <Button
                            // variant='outlined'
                            onClick={() => {
                                window.location.href = 'https://beasiswapapuatengah.id'
                            }}
                            sx={{
                                textTransform: 'none',
                                color: 'black',
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: bgColor
                                },
                            }}
                        >
                            <Icon icon='material-symbols:arrow-back' />
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '12px' : '13px',
                                    fontWeight: '400',
                                    lineHeight: '20.16px',
                                    cursor: 'pointer',
                                    marginLeft: '10px'
                                }}
                            >
                                Kembali ke Beranda
                            </Typography>
                        </Button>
                    </Box>
                    <Button
                        onClick={handleClickMasukAkun}
                        variant='contained'
                        size='small'
                        sx={{
                            minWidth: isMobile ? '120px' : '150px',
                            // maxWidth:'100px',
                            backgroundColor: btnBgColor,
                            color: mainColor,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            padding: isMobile ? '0x 10px' : undefined,
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor
                            },
                        }}
                        disabled={isLoading ? true : false}
                    >
                        {isLoading ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                            :
                            'Masuk Akun'
                        }
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        margin: isMobile ? '40px 0px 0px 0px' : '30px 0px 34px 0px',
                        height: '5%', // You can adjust the height of other content items if needed
                        // backgroundColor: 'orange',
                    }}
                >
                    <Box
                        sx={{
                            marginBottom: isMobile ? '20px' : '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: isMobile ? '18px' : '22px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                                color: '#1E222F'
                            }}
                        >
                            Pendaftaran Beasiswa Papua Tengah
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: '70%', // You can adjust the height of other content items if needed
                        overflowY: 'auto'
                        // backgroundColor: 'orange',
                    }}
                >
                    <Card
                        sx={{
                            display: 'flex',
                            // justifyContent:'center',
                            // alignItems:'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: isMobile ? '10px' : '15px',
                                backgroundColor: 'white',
                                width: '33.3%',
                                // cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Tahap I
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: isMobile ? '13px' : '14px',
                                    lineHeight: isMobile ? '21px' : 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Registrasi Akun
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: '15px',
                                backgroundColor: mainColor,
                                width: '33.3%',
                                // cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'white',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Tahap II
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'white',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: isMobile ? '13px' : '14px',
                                    lineHeight: isMobile ? '21px' : 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Lengkapi Persyaratan
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: '15px',
                                backgroundColor: 'white',
                                width: '33.3%',
                                // cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Tahap III
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: isMobile ? '13px' : '14px',
                                    lineHeight: isMobile ? '21px' : 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Menunggu Verifikasi Data
                            </Typography>
                        </Box>
                    </Card>
                    <Box
                        sx={{
                            margin: isMobile ? '46px 0px 12px 0px' : '56px 0px 24px 0px',
                            padding: isMobile ? '0px 0px' : isTablet ? '0px 50px' : '0px 160px',
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Pilih Kategori beasiswa
                                </Typography>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: '24px',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid container item>
                                            {activeCategory.map((item, index) => (
                                                <Grid
                                                    key={index}
                                                    item
                                                    xs={isMobile ? 12 : 6}
                                                    onClick={() => {
                                                        handleCategoryClick(item)
                                                    }}
                                                    sx={{
                                                        // padding: '14px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: isMobile ? 'flex-start' : 'center',
                                                            alignItems: 'center',
                                                            gap: '8px',
                                                            padding: '16px',
                                                            cursor: 'pointer',
                                                            backgroundColor: selectedCategory === item.category ? mainColor : item.status === false ? 'white' : 'white',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: isMobile ? 'flex-start' : 'center',
                                                                alignItems: 'center',
                                                                gap: '8px',
                                                            }}
                                                        >
                                                            {selectedCategory === item.category && (
                                                                <Icon icon="icon-park-solid:check-one" style={{ fontSize: '18px', color: 'white' }} />
                                                            )}
                                                            <Typography
                                                                sx={{
                                                                    color: selectedCategory === item.category ? '#E6ECFC' : item.status === false ? TextGray : 'black',
                                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                                    fontSize: '14px',
                                                                    fontWeight: selectedCategory === item.category ? 'bold' : '500',
                                                                }}
                                                            >
                                                                {item.category}
                                                            </Typography>
                                                        </Box>
                                                        {!item.status &&
                                                            <Box
                                                                sx={{
                                                                    margin: '-4px 0px 0px -5px'
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        color: red[700],
                                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                                        fontSize: '11px',
                                                                        fontWeight: 'bold',
                                                                    }}
                                                                >
                                                                    Ditutup
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>

                                    </Grid>
                                </Card>
                            </Grid>


                            {selectedCategory === 'Beasiswa Berprestasi' || (selectedCategory === 'Bantuan Biaya Pendidikan' || selectedCategory === 'Bantuan Studi Akhir') ?
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Jenjang pendidikan yang akan ditempuh
                                    </Typography>
                                    <Card
                                        sx={{
                                            display: 'flex',
                                            flexDirection: isMobile ? 'column' : 'row',
                                            marginBottom: '24px',
                                        }}
                                    >
                                        {['Strata 1(S1)', 'Strata 2(S2)', 'Strata 3(S3)'].map((jenjangPendidikan, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                md={isMobile ? 12 : 4}
                                                onClick={() => {
                                                    handleJenjangPendidikan(jenjangPendidikan)
                                                }}
                                                sx={{
                                                    padding: '14px',
                                                    cursor: 'pointer',
                                                    backgroundColor: selectedJenjangPendidikan === jenjangPendidikan ? mainColor : 'white',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: isMobile ? 'flex-start' : 'center',
                                                        alignItems: 'center',
                                                        gap: '8px',
                                                    }}
                                                >
                                                    {selectedJenjangPendidikan === jenjangPendidikan && (
                                                        <Icon icon="icon-park-solid:check-one" style={{ fontSize: '18px', color: 'white' }} />
                                                    )}
                                                    <Typography
                                                        sx={{
                                                            color: selectedJenjangPendidikan === jenjangPendidikan ? '#E6ECFC' : 'black',
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '14px',
                                                            fontWeight: selectedJenjangPendidikan === jenjangPendidikan ? 'bold' : '500',
                                                        }}
                                                    >
                                                        {jenjangPendidikan}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Card>
                                </Grid>
                                :
                                undefined
                            }

                            <Grid container>
                                {(selectedCategory === 'Beasiswa Berprestasi' || selectedCategory === 'Bantuan Biaya Pendidikan') &&
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                                marginBottom: '14px',
                                            }}
                                        >
                                            Lengkapi Persyaratan
                                        </Typography>
                                    </Grid>
                                }
                                {selectedCategory === 'Ikatan Dinas' &&

                                    <Grid item xs={12} md={12} >
                                        <Box
                                            sx={{
                                                marginBottom: '24px',
                                                padding: '10px 16px',
                                                backgroundColor: '#F8D70033',

                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#1E222F',
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: isMobile ? '14px' : '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '24px',
                                                    textAlign: 'justify'
                                                }}
                                            >
                                                Kategori Ikatan Dinas diperuntukkan hanya untuk jenjang pendidikan Strata 1 (S1)
                                            </Typography>
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                                marginBottom: '12px',
                                            }}
                                        >
                                            Lengkapi Persyaratan
                                        </Typography>
                                    </Grid>

                                }

                                {selectedCategory === 'Beasiswa Keluarga Miskin' &&

                                    <Grid item xs={12} md={12}>
                                        <Box
                                            sx={{
                                                marginBottom: '24px',
                                                padding: '10px 16px',
                                                backgroundColor: '#F8D70033'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#1E222F',
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: isMobile ? '14px' : '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '24px',
                                                    textAlign: 'justify'
                                                }}
                                            >
                                                Kategori beasiswa keluarga miskin atau miskin ekstrim diperuntukkan hanya untuk jenjang pendidikan Strata 1 (S1)
                                            </Typography>
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                                marginBottom: '12px',
                                            }}
                                        >
                                            Lengkapi Persyaratan
                                        </Typography>

                                    </Grid>
                                }

                                {/* Textfield NIM */}
                                <Grid item xs={12} sm={12} mb='15px'>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        type="text"
                                        label="Masukkan Nomor Induk Mahasiswa"
                                        // placeholder='Contoh : 2.75'
                                        value={newData.NIM}
                                        name="NIM"
                                        onChange={(e) => handleChange(e)}
                                        // error={newData.NIM === '' ? undefined : !!errorGpaS1}
                                        // helperText={errorGpaS1}
                                        // onInput={(e) => {
                                        //   // Filter out non-numeric characters
                                        //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                        // }}
                                        autoComplete="off"
                                        required
                                        sx={{
                                            width: isMobile ? '100%' : '40%',
                                            maxWidth: '40%',
                                            minWidth: isMobile ? '100%' : '40px',
                                            fontSize: isMobile ? '14px' : '13px',
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "white",
                                            },
                                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                "& fieldset": {
                                                    borderBottomColor: mainColor,
                                                },
                                            },
                                            "& .MuiInputLabel-root.Mui-disabled": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiInputLabel-root": {
                                                color: '#B3B3D4',
                                                fontWeight: 'bold',
                                                fontSize: '13px',
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                                color: mainColor,
                                            },
                                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                "-webkit-appearance": "none",
                                                margin: 0,
                                            },
                                            "& input[type=number]": {
                                                "-moz-appearance": "textfield",
                                            },
                                        }}
                                    />
                                </Grid>


                                {/* Gambar NIM */}
                                <Grid item xs={12} sm={12} mb='25px' mt='10px'>
                                    <Typography
                                        sx={{
                                            color: '#1E222F',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '20px',
                                            textAlign: 'justify',
                                            marginBottom: '10px',
                                            // marginTop: '34px'
                                        }}
                                    >
                                        Foto Kartu Mahasiswa (Maks 1 MB)
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '24px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                maxWidth: '486px',
                                                minWidth: '40%',
                                                width: isMobile ? '100%' : '40%',
                                                height: '190px',
                                                backgroundColor: '#fff',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: '12px',
                                                position: 'relative',
                                                border: `solid 2px ${DividerColor}`,
                                                borderStyle: 'dashed',
                                            }}
                                            onDragOver={(e) => handleDragOver(e, 'nim')}
                                            onDrop={(e) => handleDrop(e, 'nim')}
                                            onDragLeave={handleDragLeave}
                                        >
                                            {isDragging.isDraggingNIM && !isMobile ?
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 'bold',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center',
                                                        color: mainColor
                                                    }}
                                                >
                                                    Lepaskan untuk mengunggah
                                                </Typography>
                                                :
                                                selectedImageNIM && selectedImageNIM.type === 'application/pdf' ? (
                                                    // Handle PDF display logic
                                                    <>
                                                        {handleShowPDF(selectedImageNIM.name)}
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '4px',
                                                                right: '4px',
                                                                backgroundColor: '#fff',
                                                                borderRadius: '50%',
                                                                padding: '2px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleRemoveImage('nim')}
                                                        >
                                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                        </Box>
                                                    </>
                                                ) : imageUrlNIM ? (
                                                    // Handle image display logic
                                                    <>
                                                        <img
                                                            src={imageUrlNIM}
                                                            alt="selected-image"
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'contain',
                                                                borderRadius: '4px',
                                                            }}
                                                        />
                                                        <Box
                                                            sx={{
                                                                position: 'absolute',
                                                                top: '4px',
                                                                right: '4px',
                                                                backgroundColor: '#fff',
                                                                borderRadius: '50%',
                                                                padding: '2px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleRemoveImage('nim')}
                                                        >
                                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                        </Box>
                                                    </>
                                                ) : (
                                                    // Display upload options
                                                    <>
                                                        {!isMobile ?
                                                            <>
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                                        fontSize: '13px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        lineHeight: '17,64px',
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    {imageUrlNIM ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                                        fontSize: '11px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        lineHeight: '17,64px',
                                                                        color: TextGray
                                                                    }}
                                                                >
                                                                    atau
                                                                </Typography>
                                                            </>
                                                            :
                                                            undefined
                                                        }
                                                        <div
                                                            onClick={() => handleImageUpload(fileInputRefNIM)}
                                                            style={{
                                                                cursor: 'pointer',
                                                                padding: '6px 12px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '4px',
                                                                display: 'inline-block',
                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                fontSize: '12px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '17,64px',
                                                                backgroundColor: bgColor
                                                            }}
                                                        >
                                                            Pilih {imageUrlNIM ? 'Gambar' : 'Gambar/PDF'}
                                                        </div>
                                                    </>
                                                )}
                                            <input
                                                type="file"
                                                accept="image/*,.pdf"  // Accept both image and PDF files
                                                onChange={(e) => handleFileInputChange(e.target.files[0], 'nim')}
                                                ref={fileInputRefNIM}
                                                style={{ display: 'none' }}
                                            />
                                        </Box>
                                        {!isMobile && (
                                            <CustomTooltip
                                                message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                                                padding='8px'
                                                customWidth='281px'
                                            />
                                        )}
                                    </Box>
                                </Grid>

                                {/* Dropdown Universitas */}
                                <Grid item xs={12} md={12}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        textAlign: 'center',
                                        // marginBottom: verificationStatus ? isMobile ? '24px' : '24px' : undefined,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '20px',
                                            width: isMobile ? '100%' : '40%',
                                            maxWidth: '40%',
                                            minWidth: isMobile ? '100%' : '40px',
                                            fontSize: isMobile ? '14px' : '13px',
                                            // minWidth: isMobile ? '100%' : '40%'
                                            // backgroundColor: 'orange',
                                            margin: selectedUniverity ? '10px 0px 10px 0px' : '0px 0px 10px 0px'
                                        }}
                                    >
                                        <Autocomplete
                                            fullWidth
                                            // disabled={verificationStatus ? false : true}
                                            freeSolo
                                            autoSelect // Aktifkan pemilihan otomatis saat mengetik
                                            selectOnFocus // Aktifkan pemilihan saat fokus dengan tombol panah bawah
                                            sx={{ marginBottom: '10px' }}
                                            id="university"
                                            options={listUniversity.sort((a, b) => a.localeCompare(b))} // Mengurutkan opsi universitas secara alfabetis
                                            getOptionLabel={(option) => option} // Anda hanya perlu menggunakan nilai district itu sendiri sebagai label
                                            value={selectedUniverity} // Mengikuti nilai yang dipilih
                                            onChange={(event, newValue) => {
                                                setSelectedUniverityNew(newValue); // Perbarui nilai selectedDistrict
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant='standard'
                                                    label="Pilih Universitas"
                                                    // placeholder='asdsad'
                                                    required
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: listUniversity.length > 0 ? (
                                                            setSelectedUniverity(selectedUniverityNew ? selectedUniverityNew : selectedUniverity)
                                                        )
                                                            :
                                                            (
                                                                <InputAdornment
                                                                    sx={{
                                                                        // marginLeft:'5px'
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            // color: '#1E222F',
                                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                                            fontSize: '14px',
                                                                            lineHeight: '20px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            marginRight: '6px'
                                                                        }}
                                                                    >
                                                                        Loading
                                                                    </Typography>
                                                                    <Icon icon='svg-spinners:3-dots-fade' width='20px' height='20px' />
                                                                </InputAdornment>
                                                            ),
                                                        // type: 'search',
                                                        // sx: { margin: selectedUniverity ? '15px 0px 10px 0px' : '0px 0px 0px 0px' }
                                                    }}
                                                />
                                            )}
                                            disabled={listUniversity.length > 0 ? false : true}
                                        />
                                    </Box>
                                </Grid>

                                {/* Textfield Fakultas */}
                                <Grid item xs={12} sm={12} mb='15px'>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        type="text"
                                        label="Masukkan Nama Fakultas"
                                        // placeholder='Contoh : 2.75'
                                        value={newData.fakultas}
                                        name="fakultas"
                                        onChange={(e) => handleChange(e)}
                                        autoComplete="off"
                                        required
                                        sx={{
                                            width: isMobile ? '100%' : '40%',
                                            maxWidth: '40%',
                                            minWidth: isMobile ? '100%' : '40px',
                                            fontSize: isMobile ? '14px' : '13px',
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "white",
                                            },
                                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                "& fieldset": {
                                                    borderBottomColor: mainColor,
                                                },
                                            },
                                            "& .MuiInputLabel-root.Mui-disabled": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiInputLabel-root": {
                                                color: '#B3B3D4',
                                                fontWeight: 'bold',
                                                fontSize: '13px',
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                                color: mainColor,
                                            },
                                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                "-webkit-appearance": "none",
                                                margin: 0,
                                            },
                                            "& input[type=number]": {
                                                "-moz-appearance": "textfield",
                                            },
                                        }}
                                    />
                                </Grid>

                                {/* Textfield Program Studi */}
                                <Grid item xs={12} sm={12} mb='15px' mt='5px'>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        type="text"
                                        label="Masukkan Nama Program Studi"
                                        // placeholder='Contoh : 2.75'
                                        value={newData.programStudi}
                                        name="programStudi"
                                        onChange={(e) => handleChange(e)}
                                        autoComplete="off"
                                        required
                                        sx={{
                                            width: isMobile ? '100%' : '40%',
                                            maxWidth: '40%',
                                            minWidth: isMobile ? '100%' : '40px',
                                            fontSize: isMobile ? '14px' : '13px',
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "white",
                                            },
                                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                "& fieldset": {
                                                    borderBottomColor: mainColor,
                                                },
                                            },
                                            "& .MuiInputLabel-root.Mui-disabled": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiInputLabel-root": {
                                                color: '#B3B3D4',
                                                fontWeight: 'bold',
                                                fontSize: '13px',
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                                color: mainColor,
                                            },
                                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                "-webkit-appearance": "none",
                                                margin: 0,
                                            },
                                            "& input[type=number]": {
                                                "-moz-appearance": "textfield",
                                            },
                                        }}
                                    />
                                </Grid>

                                {/* Textfield Fakultas */}
                                <Grid item xs={12} sm={12} mt='5px'>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        type="text"
                                        label="Masukkan Semester Sekarang"
                                        // placeholder='Contoh : 2.75'
                                        value={newData.semesterSekarang}
                                        name="semesterSekarang"
                                        onChange={(e) => handleChange(e)}
                                        onInput={(e) => {
                                            // Filter out non-numeric characters
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                        }}
                                        autoComplete="off"
                                        required
                                        sx={{
                                            width: isMobile ? '100%' : '40%',
                                            maxWidth: '40%',
                                            minWidth: isMobile ? '100%' : '40px',
                                            fontSize: isMobile ? '14px' : '13px',
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "white",
                                            },
                                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                "& fieldset": {
                                                    borderBottomColor: mainColor,
                                                },
                                            },
                                            "& .MuiInputLabel-root.Mui-disabled": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiInputLabel-root": {
                                                color: '#B3B3D4',
                                                fontWeight: 'bold',
                                                fontSize: '13px',
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                                color: mainColor,
                                            },
                                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                "-webkit-appearance": "none",
                                                margin: 0,
                                            },
                                            "& input[type=number]": {
                                                "-moz-appearance": "textfield",
                                            },
                                        }}
                                    />
                                </Grid>

                                {/* Datepicker Bulan / Tahun */}
                                <Grid item xs={12} sm={12} mt='30px' mb='15px'>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            color: Dark,
                                            fontSize: '14px',
                                            fonStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: 'normal',

                                        }}
                                    >
                                        Bulan / Tahun Masuk
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            gap: '14px',
                                            // marginTop: '-10px',
                                            width: isMobile ? '100%' : '40%',
                                            maxWidth: '40%',
                                            minWidth: isMobile ? '100%' : '40px',
                                            fontSize: isMobile ? '14px' : '13px',
                                        }}
                                    >
                                        <DatePicker
                                            views={['year', 'month']}
                                            minDate={new Date('2000-01-01')}
                                            maxDate={new Date('2100-12-31')}
                                            // label='Bulan dan Tahun Masuk Perguruan Tinggi'
                                            // localeText={{ toolbarTitle: 'Title' }}
                                            sx={{ gridColumn: 'span 2', padding: '0px', width: '100%', marginBottom: '16px' }}
                                            slotProps={{
                                                textField: { variant: 'standard' },
                                                toolbar: {
                                                    toolbarPlaceholder: '__',
                                                    toolbarFormat: 'yyyy-mm-dd',
                                                    hidden: true,
                                                },
                                            }}
                                            // slots={{
                                            //   toolbar: CustomToolbarComponent
                                            // }}
                                            value={monthAndYear}
                                            onChange={(newValue) => setMonthAndYear(newValue)}
                                        // disabled={switchButton ? false : true}
                                        />
                                    </Box>
                                </Grid>

                                {selectedCategory === 'Beasiswa Berprestasi' ?
                                    <>
                                        <BeasiswaBerprestasi
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                            selectedCategory={selectedCategory}
                                            selectedJenjangPendidikan={selectedJenjangPendidikan}
                                            activeCategory={activeCategory}
                                        />
                                    </>
                                    :
                                    selectedCategory === 'Beasiswa Keluarga Miskin' ?
                                        <>

                                            <BeasiswaKeluargaMiskin
                                                isMobile={isMobile}
                                                isTablet={isTablet}
                                                selectedCategory={selectedCategory}
                                                selectedJenjangPendidikan={selectedJenjangPendidikan}
                                                activeCategory={activeCategory}
                                            />
                                        </>
                                        :
                                        selectedCategory === 'Ikatan Dinas' ?
                                            <>
                                                <IkatanDinas
                                                    isMobile={isMobile}
                                                    isTablet={isTablet}
                                                    selectedCategory={selectedCategory}
                                                    selectedJenjangPendidikan={selectedJenjangPendidikan}
                                                    activeCategory={activeCategory}
                                                />
                                            </>

                                            :
                                            selectedCategory === 'Bantuan Biaya Pendidikan' ?
                                                <>
                                                    <Kuisioner
                                                        isMobile={isMobile}
                                                        isTablet={isTablet}
                                                        selectedCategory={selectedCategory}
                                                        selectedJenjangPendidikan={selectedJenjangPendidikan}
                                                        activeCategory={activeCategory}
                                                        newData={newData}
                                                        selectedUniverityNew={selectedUniverityNew}
                                                        monthAndYear={monthAndYear}
                                                        selectedImageNIM={selectedImageNIM}
                                                        imageUrlNIM={imageUrlNIM}
                                                        dataPersonalInformation={dataPersonalInformation}
                                                        isPageLoadingTrue={() => setIsPageLoading(true)}
                                                        isPageLoadingFalse={() => setIsPageLoading(false)}
                                                    />
                                                </>
                                                :
                                                undefined

                                }
                            </Grid>
                            <NotifTahapII
                                open={openNotifModal}
                                close={() => setOpenNotifModal(false)}
                                text={text}
                                selectedCategory={selectedCategoryNew.category}
                                selectedJenjangPendidikan={selectedJenjangPendidikanNew}
                                defaultValueJenjangPendidikan={() => {
                                    setSelectedCategory(selectedCategoryNew.category ? selectedCategoryNew.category : selectedCategory)
                                    setSelectedJenjangPendidikan(selectedJenjangPendidikanNew ? selectedJenjangPendidikanNew : selectedJenjangPendidikan)
                                }}
                            />
                            <NotifRefreshPage
                                open={OpenNotifRefreshPage}
                                close={() => setOpenNotifRefreshPage(false)}
                            />
                            {isPageLoading ?
                                <LoadingData
                                    open={isPageLoading}
                                // close={}
                                />
                                :
                                undefined
                            }
                        </Grid>
                    </Box>
                </Grid>
                <ToastContainer style={{ width: '100%', maxWidth: isMobile ? '100%' : '350px' }} />
            </Grid>
            <Footer />
        </>
    )
}

export default RegistrasiTahap2