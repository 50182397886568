import React, { useState, useRef, useEffect } from 'react';
import { Dark, DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import { Typography, Box, Card, Button, TextareaAutosize, Divider, Checkbox, Grid, Autocomplete, TextField, useMediaQuery } from '@mui/material'; // Import TextareaAutosize from MUI
import { logDOM } from '@testing-library/react';
import { Icon } from '@iconify/react';
import { useFormContext } from './FormContext';

import ErrorAlert from '../../components/ToastyAlert/ErrorAlert';
import axios from 'axios';
import { RegistrasiTahapII_and_TahapIII, agreementQuestion, cekRegistrasiUser, deletepersonalinformation, listDataUniversity, simpanDataTahap3 } from '../../components/Config/Api';
import SuccessAlert from '../../components/ToastyAlert/SuccessAlert';
import IconCheck from '../../assets/Icon/check.png'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import QRCode from 'react-qr-code';
import VerificationDataModal from '../../components/Modal/VerificationDataModal/VerificationDataModal';
import { encrypt, decrypt } from 'n-krypta';
import { secretKey } from '../../components/Config/SecretKey';
import { red } from '@mui/material/colors';
import LocationDetectorModal from '../../components/Modal/LocationDetector/LocationDetectorModal';
import LoadingData from '../../components/LoadingPage/LoadingData';
import FileToBase64 from '../../components/FileToBase64/FileToBase64';
import Base64ToFile from '../../components/Base64ToFile/Base64ToFile';
import { listBank } from '../../components/Config/ListBank';
import Top from '../../components/Register/Top/Top';
import Footer from '../../components/Register/Footer/Footer';
import { ToastContainer } from 'react-toastify';

const RegistrasiTahap3 = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    const navigate = useNavigate()

    const [selectedImageTTD, setSelectedImageTTD] = useState(null);
    const [imageUrlTTD, setImageUrlTTD] = useState(null);
    const fileInputRefTTD = useRef(null);

    const [selectedBukuTabungan, setSelectedBukuTabungan] = useState(null);
    const [imageUrlBukuTabungan, setImageUrlBukuTabungan] = useState(null);
    const fileInputRefBukuTabungan = useRef(null);
    const [isLoading, setIsLoading] = useState(false)

    const [emailUser, setEmailUser] = useState('')
    const [listAgreement, setListAgreement] = useState(null)
    const [categoryBeasiswa, setCategoryBeasiswa] = useState(null)
    const [loadingSimpanData, setloadingSimpanData] = useState(false)
    const [answer, setAnswer] = useState(''); // State to manage the value of the textarea
    const [answer2, setAnswer2] = useState(''); // State to manage the value of the textarea
    const [userSignature, setUserSignature] = useState(''); // State to manage the value of the textarea
    const [qrSignature, setQrSignature] = useState(''); // State to manage the value of the textarea
    const [checkboxValues, setCheckboxValues] = useState([]); // Menyimpan status tiap checkbox
    const [finalAgreementChecked, setFinalAgreementChecked] = useState(false); // Checkbox terakhir

    const [dataListBank, setDataListBank] = useState([])
    const [selectedBank, setSelectedBank] = useState(null)
    const [newData, setNewData] = useState({
        nomorRekening: '',
    })

    const [notificationSuccessRegister, setNotificationSuccessRegister] = useState('N')
    const [finishRegister, setFinishRegister] = useState('N')
    const [loadingKonfirmasiPendaftaran, setLoadingKonfirmasiPendaftaran] = useState(false)

    const [openVerificationDataModal, setOpenVerificationDataModal] = useState(false)
    const [openLocationDetectorModal, setOpenLocationDetectorModal] = useState(false)

    const [location, setLocation] = useState(null);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [permissionStatus, setPermissionStatus] = useState('');
    const [dataUsers, setDataUsers] = useState('');
    const [isButtonPermissionLoading, setIsButtonPermissionLoading] = useState(false);

    const maxCharactersAnswer1 = 100;
    const minCharactersAnswer1 = 100;
    const maxCharactersAnswer2 = 100;
    const minCharactersAnswer2 = 100;

    const [isDragging, setIsDragging] = useState({
        isDraggingDigitalTTD: false,
        isDraggingBukuTabungan: false
    });

    // const secretKey = 'key'

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const [isPageLoading, setIsPageLoading] = useState(false);

    const handleImageUpload = (fileInput) => {
        fileInput.current.click();
    };

    const handleRemoveImage = (removeImage) => {

        if (removeImage === 'user signature') {
            setSelectedImageTTD(null);
            setImageUrlTTD(null);
        } else if (removeImage === 'buku tabungan') {
            setSelectedBukuTabungan(null);
            setImageUrlBukuTabungan(null);
        }
    };

    const handleDragOver = (event, option) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        if (option === 'user signature') {
            setIsDragging({
                isDraggingDigitalTTD: true
            })
        } else if (option === 'buku tabungan') {
            setIsDragging({
                isDraggingBukuTabungan: true
            })
        }
    };

    const handleDrop = (event, option) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file, option);
        if (option === 'user signature') {
            setIsDragging({
                isDraggingDigitalTTD: false
            })
        } else if (option === 'buku tabungan') {
            setIsDragging({
                isDraggingBukuTabungan: false
            })
        }
    };

    const handleDragLeaveBukuTabungan = () => {
        setIsDragging({
            isDraggingBukuTabungan: false,
        }) // Set state menjadi false saat dragleave
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingDigitalTTD: false,
        }) // Set state menjadi false saat dragleave
    };

    const handleFileInputChange = (file, fileInputChange) => {
        // const file = event.target.files[0];
        // console.log(file);
        if (file) {
            // Check the file size
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto/Pdf 1 MB',
                    position: 'top-center'
                })
                // // Optionally, clear the file input
                // event.target.value = null;
                // return;
            } else {
                // Check the file type
                const fileType = file.type;
                const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
                const allowedPdfTypes = ['application/pdf'];

                if (allowedImageTypes.includes(fileType)) {
                    // Handle Image File
                    if (fileInputChange === 'user signature') {
                        setSelectedImageTTD(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlTTD(imageUrl);
                    } else if (fileInputChange === 'buku tabungan') {
                        setSelectedBukuTabungan(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlBukuTabungan(imageUrl);
                    }
                } else if (allowedPdfTypes.includes(fileType)) {
                    // Handle PDF file
                    if (fileInputChange === 'user signature') {
                        setSelectedImageTTD(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlTTD(imageUrl);
                    } else if (fileInputChange === 'buku tabungan') {
                        setSelectedBukuTabungan(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlBukuTabungan(imageUrl);
                    }
                } else {
                    // Unsupported file type
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png, gif dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }

            }
            if (fileInputChange === 'buku tabungan') {
                fileInputRefBukuTabungan.current.value = null
            } else if (fileInputChange === 'user signature') {
                fileInputRefTTD.current.value = null
            }
        }

    };

    const cekDataUser = async () => {
        const activationEmail = localStorage.getItem('ActivasiEmail')


        if (activationEmail) {
            const { email } = JSON.parse(activationEmail)
            const formData = new FormData();
            formData.append('personal_email', email);
            // for (let pair of formData.entries()) {
            //     console.log(pair[0] + ', ' + pair[1]);
            // }
            await axios.post(`${cekRegistrasiUser}`, formData)
                .then((response) => {
                    console.log('cekRegistrasiUser', response.data);
                    const { personalInformation } = response.data
                    // console.log(personalInformation);
                    setEmailUser(personalInformation.data.personal_email)
                    setDataUsers(response.data)
                }).catch((error) => {
                    console.log(error.response);
                });
        }
    }

    const getListBank = () => {
        const daftarbank = listBank.map(item => item.bank_name);
        setDataListBank(daftarbank)
    }

    useEffect(() => {
        const dataLogin = localStorage.getItem('LogInfo')
        if (dataLogin) {
            navigate('/dashboard-applicant')
        } else {
            setIsPageLoading(true)
            setTimeout(() => {
                cekDataUser();
                getDataListAgreement();
                getListBank()
                window.scrollTo(0, 0);
                setIsPageLoading(false)
            }, 2500);
        }
    }, [])

    const getDataListAgreement = async () => {
        await axios.get(agreementQuestion)
            .then((response) => {
                console.log(response.data);
                setCategoryBeasiswa(response.data[0].category_name);
                setListAgreement(response.data[0].descriptions);
            }).catch((error) => {
                console.log(error.response.data);
            });

    };

    // console.log('data location', location);

    // const askForLocationPermission = async () => {
    //     if (permissionStatus === 'denied' || permissionStatus === 'prompt') {
    //         setIsButtonPermissionLoading(true)
    //     }

    //     navigator.geolocation.getCurrentPosition(
    //         async (position) => {
    //             try {
    //                 const { latitude, longitude } = position.coords;
    //                 const locationData = await fetch(
    //                     `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=id`
    //                 );
    //                 const locationJson = await locationData.json();
    //                 setLocation(locationJson);
    //                 // console.log('locationJson', locationJson,);
    //                 if (permissionStatus === 'denied' || permissionStatus === 'prompt') {
    //                     setIsButtonPermissionLoading(true)
    //                     setOpenLocationDetectorModal(false)
    //                     setIsButtonPermissionLoading(false)
    //                     SuccessAlert({
    //                         message: ' Berhasil memberikan akses lokasi',
    //                         position: 'top-center'
    //                     })
    //                     setloadingSimpanData(false)
    //                 }

    //                 // setOpenVerificationDataModal(true)
    //                 setPermissionStatus('')
    //             } catch (error) {
    //                 console.error('Error fetching location:', error);
    //                 setIsButtonPermissionLoading(false)
    //             }
    //         },
    //         (error) => {
    //             if (error.code === error.PERMISSION_DENIED) {
    //                 setPermissionDenied(true);
    //             }
    //             console.error('Error getting user location:', error);
    //             ErrorAlert({
    //                 message: 'Wajib memberikan akses lokasi',
    //                 position: 'top-center'
    //             })
    //             if (permissionStatus === 'denied' || permissionStatus === 'prompt') {
    //                 setIsButtonPermissionLoading(false)
    //                 setloadingSimpanData(false)
    //             }

    //         }
    //     );
    // };

    // // console.log(permissionDenied);

    // // Check location permission status when component mounts
    // const checkLocationPermission = async () => {
    //     try {
    //         const permission = await navigator.permissions.query({ name: 'geolocation' });
    //         // console.log(permission.state)
    //         if (permission.state === 'denied' || permission.state === 'prompt') {
    //             setOpenLocationDetectorModal(true)
    //         } else if (permission.state === 'granted') {
    //             askForLocationPermission()
    //         }
    //         setPermissionStatus(permission.state)
    //     } catch (error) {
    //         console.error('Error checking location permission:', error);
    //     }
    // };

    // useEffect(() => {
    //     checkLocationPermission()
    //     getData()
    // }, [])



    const handleShowPDF = (fileName) => {

        return (
            <>
                <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: mainColor,
                        fontSize: '11px',
                        fonStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginTop: '-10px'
                    }}
                >
                    {fileName}
                </Typography>
            </>
        )
    }


    const handleTextareaChange = (event) => {
        setAnswer(event.target.value);
    };

    const handleTextareaChange2 = (event) => {
        setAnswer2(event.target.value);
    };

    // Fungsi untuk menangani perubahan checkbox pada pernyataan (agreement)
    const handleAgreementCheckboxChange = (index) => {
        const updatedAgreements = [...checkboxValues];
        updatedAgreements[index] = !updatedAgreements[index]; // Toggle nilai checkbox
        // console.log(updatedAgreements);
        // Jika ada checkbox pernyataan yang di-uncheck, uncheck juga checkbox terakhir
        if (!updatedAgreements.every((checked) => checked)) {
            setFinalAgreementChecked(false);
        }
        setCheckboxValues(updatedAgreements);
    };

    // Fungsi untuk menangani perubahan checkbox terakhir
    const handleFinalCheckboxChange = () => {
        // Checkbox terakhir hanya bisa dicentang jika semua pernyataan telah dicentang
        if (checkboxValues.every((checked) => checked)) {
            setFinalAgreementChecked(!finalAgreementChecked);
        }
    };

    const handleSubmitSuccessRegistered = () => {
        setLoadingKonfirmasiPendaftaran(true)
        setTimeout(() => {
            navigate('/login')
            setLoadingKonfirmasiPendaftaran(false)
        }, 1500);
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setloadingSimpanData(true)
        setIsPageLoading(true)
        // if (permissionStatus === 'prompt' || permissionStatus === 'denied') {
        //     setOpenLocationDetectorModal(true)
        // } 
        if (finalAgreementChecked &&
            answer !== '' &&
            answer2 !== '' &&
            selectedImageTTD &&
            answer.length > minCharactersAnswer1 &&
            answer2.length > minCharactersAnswer2 &&
            selectedBank &&
            newData.nomorRekening &&
            selectedBukuTabungan
        ) {

            const { scholarship } = dataUsers

            const formData = new FormData();
            formData.append('scholarship_application_id', scholarship.data.scholarship_application_id);
            formData.append('personal_information_id', scholarship.data.personal_information_id);
            formData.append('name_bank', selectedBank);
            formData.append('account_number', newData.nomorRekening);
            formData.append('savings_book_scan', selectedBukuTabungan);
            formData.append('answer1', answer);
            formData.append('answer2', answer2);
            formData.append('signature', selectedImageTTD);

            for (let pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1])
            }

            try {
                const response = await axios.post(`${simpanDataTahap3}`, formData);
                console.log(response.data);
                SuccessAlert({
                    message: 'Berhasil menyimpan data',
                    position: 'top-center'
                })
                cekDataUser()
                localStorage.removeItem('ActivasiEmail')
                setTimeout(() => {
                    // setOpenVerificationDataModal(true)
                    setNotificationSuccessRegister('Y')
                    setloadingSimpanData(false)
                    setIsPageLoading(false)
                }, 2500);
            } catch (error) {
                console.log(error.response);
                setloadingSimpanData(false)
                setIsPageLoading(false)
            }



        } else if (answer.length < minCharactersAnswer1 || answer2.length < minCharactersAnswer2) {
            ErrorAlert({
                message: 'Jawaban minimal 100 karakter atau lebih',
                position: 'top-center'
            })
            setloadingSimpanData(false)
            setIsPageLoading(false)
        } else if (finalAgreementChecked === false) {
            ErrorAlert({
                message: 'Wajib menyetujui semua pernyataan',
                position: 'top-center'
            })
            setloadingSimpanData(false)
            setIsPageLoading(false)
        } else if (selectedImageTTD === null) {
            ErrorAlert({
                message: 'Wajib upload tanda tangan digital.',
                position: 'top-center'
            })
            setloadingSimpanData(false)
            setIsPageLoading(false)
        } else if (selectedBank === '') {
            ErrorAlert({
                message: 'Silahkan Pilih Nama Bank.',
                position: 'top-center'
            })
            setloadingSimpanData(false)
            setIsPageLoading(false)
        } else if (newData.nomorRekening === '') {
            ErrorAlert({
                message: 'Wajib mengisi Nomor Rekening.',
                position: 'top-center'
            })
            setloadingSimpanData(false)
            setIsPageLoading(false)
        } else if (selectedBukuTabungan === null) {
            ErrorAlert({
                message: 'Wajib upload foto buku tabungan.',
                position: 'top-center'
            })
            setloadingSimpanData(false)
            setIsPageLoading(false)
        }
    }
    const handleClickMasukAkun = () => {
        setIsLoading(true)


        setTimeout(() => {
            setIsLoading(false)
            navigate('/login')
        }, 1500);
    }

    return (
        <>
            <Top />
            <Grid
                container
                sx={{
                    backgroundColor: bgColor,
                    padding: isMobile ? '7px 15px' : isTablet ? '7px 20px' : '7px 83px',
                    height: 'auto', // Change height to auto
                    overflow: 'hidden',
                    minHeight: 'calc(100vh - 300px)', // Ensure content takes at least the full viewport height minus header/footer
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: isMobile ? '8%' : '10%', // Adjust height based on screen size
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: isMobile ? '13px' : isTablet ? '13px' : '23px',
                        // backgroundColor:' orange'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // gap: '10px'
                        }}
                    >
                        <Button
                            // variant='outlined'
                            onClick={() => {
                                window.location.href = 'https://beasiswapapuatengah.id'
                            }}
                            sx={{
                                textTransform: 'none',
                                color: 'black',
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: bgColor
                                },
                            }}
                        >
                            <Icon icon='material-symbols:arrow-back' />
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '12px' : '13px',
                                    fontWeight: '400',
                                    lineHeight: '20.16px',
                                    cursor: 'pointer',
                                    marginLeft: '10px'
                                }}
                            >
                                Kembali ke Beranda
                            </Typography>
                        </Button>
                    </Box>
                    <Button
                        onClick={handleClickMasukAkun}
                        variant='contained'
                        size='small'
                        sx={{
                            minWidth: isMobile ? '120px' : '150px',
                            // maxWidth:'100px',
                            backgroundColor: btnBgColor,
                            color: mainColor,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            padding: isMobile ? '0x 10px' : undefined,
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor
                            },
                        }}
                        disabled={isLoading ? true : false}
                    >
                        {isLoading ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                            :
                            'Masuk Akun'
                        }
                    </Button>

                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        margin: isMobile ? '40px 0px 0px 0px' : '30px 0px 34px 0px',
                        height: '5%', // You can adjust the height of other content items if needed
                        // backgroundColor: 'orange',
                    }}
                >
                    <Box
                        sx={{
                            marginBottom: isMobile ? '20px' : '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: isMobile ? '18px' : '22px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                                color: '#1E222F'
                            }}
                        >
                            Pendaftaran Beasiswa Papua Tengah
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: '70%', // You can adjust the height of other content items if needed
                        overflowY: 'auto'
                        // backgroundColor: 'orange',
                    }}
                >
                    <Card
                        sx={{
                            display: 'flex',
                            // justifyContent:'center',
                            // alignItems:'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: isMobile ? '10px' : '15px',
                                backgroundColor: 'white',
                                width: '33.3%',
                                // cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Tahap I
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: isMobile ? '13px' : '14px',
                                    lineHeight: isMobile ? '21px' : 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Registrasi Akun
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: '15px',
                                backgroundColor: 'white',
                                width: '33.3%',
                                // cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Tahap II
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'black',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: isMobile ? '13px' : '14px',
                                    lineHeight: isMobile ? '21px' : 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Lengkapi Persyaratan
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                gap: '10px',
                                padding: '15px',
                                backgroundColor: mainColor,
                                width: '33.3%',
                                // cursor: 'pointer',
                                borderRadius: '5px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'white',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Tahap III
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    color: 'white',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: isMobile ? '13px' : '14px',
                                    lineHeight: isMobile ? '21px' : 'normal',
                                    textAlign: 'left', // Set text alignment to left
                                }}
                            >
                                Menunggu Verifikasi Data
                            </Typography>
                        </Box>
                    </Card>
                    <Box
                        sx={{
                            margin: isMobile ? '46px 0px 12px 0px' : '56px 0px 24px 0px',
                            padding: isMobile ? '0px 0px' : isTablet ? '0px 50px' : '0px 160px',
                        }}
                    >
                        {notificationSuccessRegister === 'N' ?
                            <form onSubmit={handleSubmit} style={{ overflow: 'hidden' }}>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                                marginBottom: '10px'
                                            }}
                                        >
                                            Lengkapi Kuisioner
                                        </Typography>
                                    </Grid>

                                    {/* Dropdown Nama Bank*/}
                                    <Grid item xs={12} sm={12} mb='24px' mt='10px'>
                                        <Autocomplete
                                            // disabled={verificationStatus ? false : true}
                                            freeSolo
                                            autoSelect // Aktifkan pemilihan otomatis saat mengetik
                                            selectOnFocus // Aktifkan pemilihan saat fokus dengan tombol panah bawah
                                            sx={{
                                                marginBottom: '10px',
                                                width: isMobile ? '100%' : '40%',
                                                maxWidth: '40%',
                                                minWidth: isMobile ? '100%' : '40px',
                                                fontSize: isMobile ? '14px' : '13px',
                                            }}
                                            id="bank"
                                            options={dataListBank.sort((a, b) => a.localeCompare(b))} // Mengurutkan opsi universitas secara alfabetis
                                            getOptionLabel={(option) => option} // Anda hanya perlu menggunakan nilai district itu sendiri sebagai label
                                            value={selectedBank} // Mengikuti nilai yang dipilih
                                            onChange={(event, newValue) => {
                                                setSelectedBank(newValue); // Perbarui nilai selectedDistrict
                                            }}
                                            // disabled={switchButton ? false : true}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant='standard'
                                                    label="Pilih Bank"
                                                    required
                                                    // disabled={switchButton ? false : true}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        // type: 'search',
                                                        // sx: { margin: selectedUniverity ? '15px 0px 10px 0px' : '0px 0px 0px 0px' }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {/* Textfield Nomor Rekening*/}
                                    <Grid item xs={12} sm={12} mb='24px'>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            type="text"
                                            label="Masukkan Nomor Rekening"
                                            // placeholder='Contoh : 2.75'
                                            value={newData.nomorRekening}
                                            name="nomorRekening"
                                            onChange={(e) => handleChange(e)}
                                            // error={newData.NIM === '' ? undefined : !!errorGpaS1}
                                            // helperText={errorGpaS1}
                                            // onInput={(e) => {
                                            //   // Filter out non-numeric characters
                                            //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                            // }}
                                            autoComplete="off"
                                            required
                                            sx={{
                                                width: isMobile ? '100%' : '40%',
                                                maxWidth: '40%',
                                                minWidth: isMobile ? '100%' : '40px',
                                                fontSize: isMobile ? '14px' : '13px',
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "white",
                                                },
                                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                    "& fieldset": {
                                                        borderBottomColor: mainColor,
                                                    },
                                                },
                                                "& .MuiInputLabel-root.Mui-disabled": {
                                                    color: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiInputLabel-root": {
                                                    color: '#B3B3D4',
                                                    fontWeight: 'bold',
                                                    fontSize: '13px',
                                                },
                                                "& .MuiInputLabel-root.Mui-focused": {
                                                    color: mainColor,
                                                },
                                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                    color: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:hover:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:hover:after": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus:before": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& .MuiFilledInput-underline:focus:after": {
                                                    borderBottomColor: mainColor,
                                                },
                                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                {
                                                    "-webkit-appearance": "none",
                                                    margin: 0,
                                                },
                                                "& input[type=number]": {
                                                    "-moz-appearance": "textfield",
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {/* Gambar Buku Tabungan */}
                                    <Grid item xs={12} sm={12} mb='25px' mt='10px'>
                                        <Typography
                                            sx={{
                                                color: '#1E222F',
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '20px',
                                                textAlign: 'justify',
                                                marginBottom: '10px',
                                                // marginTop: '34px'
                                            }}
                                        >
                                            Foto Buku Tabungan (Maks 1 MB)
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '24px',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    maxWidth: '486px',
                                                    minWidth: '40%',
                                                    width: isMobile ? '100%' : '40%',
                                                    height: '190px',
                                                    backgroundColor: '#fff',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: '12px',
                                                    position: 'relative',
                                                    border: `solid 2px ${DividerColor}`,
                                                    borderStyle: 'dashed',
                                                }}
                                                onDragOver={(e) => handleDragOver(e, 'buku tabungan')}
                                                onDrop={(e) => handleDrop(e, 'buku tabungan')}
                                                onDragLeave={handleDragLeaveBukuTabungan}
                                            >
                                                {isDragging.isDraggingNIM && !isMobile ?
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '13px',
                                                            fontStyle: 'normal',
                                                            fontWeight: 'bold',
                                                            lineHeight: '17,64px',
                                                            textAlign: 'center',
                                                            color: mainColor
                                                        }}
                                                    >
                                                        Lepaskan untuk mengunggah
                                                    </Typography>
                                                    :
                                                    selectedBukuTabungan && selectedBukuTabungan.type === 'application/pdf' ? (
                                                        // Handle PDF display logic
                                                        <>
                                                            {handleShowPDF(selectedBukuTabungan.name)}
                                                            <Box
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: '4px',
                                                                    right: '4px',
                                                                    backgroundColor: '#fff',
                                                                    borderRadius: '50%',
                                                                    padding: '2px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => handleRemoveImage('buku tabungan')}
                                                            >
                                                                <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                            </Box>
                                                        </>
                                                    ) : imageUrlBukuTabungan ? (
                                                        // Handle image display logic
                                                        <>
                                                            <img
                                                                src={imageUrlBukuTabungan}
                                                                alt="selected-image"
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'contain',
                                                                    borderRadius: '4px',
                                                                }}
                                                            />
                                                            <Box
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: '4px',
                                                                    right: '4px',
                                                                    backgroundColor: '#fff',
                                                                    borderRadius: '50%',
                                                                    padding: '2px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => handleRemoveImage('buku tabungan')}
                                                            >
                                                                <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                            </Box>
                                                        </>
                                                    ) : (
                                                        // Display upload options
                                                        <>
                                                            {!isMobile ?
                                                                <>
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                                            fontSize: '13px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: '17,64px',
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        {imageUrlBukuTabungan ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{
                                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                                            fontSize: '11px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: '17,64px',
                                                                            color: TextGray
                                                                        }}
                                                                    >
                                                                        atau
                                                                    </Typography>
                                                                </>
                                                                :
                                                                undefined
                                                            }
                                                            <div
                                                                onClick={() => handleImageUpload(fileInputRefBukuTabungan)}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    padding: '6px 12px',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                    display: 'inline-block',
                                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                                    fontSize: '12px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '17,64px',
                                                                    backgroundColor: bgColor
                                                                }}
                                                            >
                                                                Pilih {imageUrlBukuTabungan ? 'Gambar' : 'Gambar/PDF'}
                                                            </div>
                                                        </>
                                                    )}
                                                <input
                                                    type="file"
                                                    accept="image/*,.pdf"  // Accept both image and PDF files
                                                    onChange={(e) => handleFileInputChange(e.target.files[0], 'buku tabungan')}
                                                    ref={fileInputRefBukuTabungan}
                                                    style={{ display: 'none' }}
                                                />
                                            </Box>
                                            {/* {!isMobile && (
                                <CustomTooltip
                                    message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                                    padding='8px'
                                    customWidth='281px'
                                />
                            )} */}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Box
                                            sx={{
                                                marginBottom: '24px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#1E222F',
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '14px',
                                                    textAlign: 'justify',
                                                    lineHeight: '20px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                Kenapa saudara(i) adalah kandidat yang tepat untuk menerima beasiswa atau bantuan ini? {<span style={{ color: red[700] }}>*</span>}
                                            </Typography>
                                            <TextareaAutosize
                                                value={answer}
                                                // maxLength={maxCharactersAnswer1}
                                                minLength={minCharactersAnswer1}
                                                onChange={handleTextareaChange}
                                                placeholder="Tulis jawaban Anda di sini..."
                                                style={{
                                                    width: isMobile ? '94%' : '98%',
                                                    height: '137px',
                                                    padding: '8px',
                                                    fontSize: '14px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    resize: 'vertical',
                                                    lineHeight: '20px'
                                                    // transition: `${mainColor} 0.3s ease-in-out`
                                                }}
                                                rowsMin={4} // You can adjust the number of rows as needed
                                                required
                                            />
                                            {/* <Box sx={{ textAlign: 'left', fontSize: '12px', color: 'gray' }}>
                                {maxCharactersAnswer1 - answer.length} / {maxCharactersAnswer1}
                            </Box> */}
                                            {answer.length < minCharactersAnswer1 && (
                                                <Box
                                                    sx={{
                                                        textAlign: 'left', fontSize: '12px', color: red[700]
                                                    }}
                                                >
                                                    Minimal {minCharactersAnswer1 - answer.length} karakter
                                                </Box>
                                            )}

                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Box
                                            sx={{
                                                marginBottom: '24px',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#1E222F',
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    marginBottom: '10px',
                                                    fontSize: '14px',
                                                    textAlign: 'justify',
                                                    lineHeight: '20px',
                                                }}
                                            >
                                                Apa yang hendak saudara(i) kontribusikan untuk Papua Tengah setelah menyelesaikan kewajiban studi saudara(i) jika terpilih? {<span style={{ color: red[700] }}>*</span>}
                                            </Typography>
                                            <TextareaAutosize
                                                value={answer2}
                                                // maxLength={maxCharactersAnswer2}
                                                minLength={minCharactersAnswer2}
                                                onChange={handleTextareaChange2}
                                                placeholder="Tulis jawaban Anda di sini..."
                                                style={{
                                                    width: isMobile ? '94%' : '98%',
                                                    height: '137px',
                                                    padding: '8px',
                                                    fontSize: '14px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    resize: 'vertical',
                                                    lineHeight: '20px'
                                                }}
                                                rowsMin={4} // You can adjust the number of rows as needed
                                                required
                                            />
                                            {/* <Box sx={{ textAlign: 'left', fontSize: '12px', color: 'gray' }}>
                                {maxCharactersAnswer2 - answer2.length} / {maxCharactersAnswer2}
                            </Box> */}
                                            {answer2.length < minCharactersAnswer2 && (
                                                <Box
                                                    sx={{
                                                        textAlign: 'left', fontSize: '12px', color: red[700]
                                                    }}
                                                >
                                                    Minimal {minCharactersAnswer2 - answer2.length} karakter
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <Card
                                        sx={{
                                            width: '100%'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                padding: '16px',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#1E222F',
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '20px',
                                                    marginBottom: '10px',
                                                    textAlign: 'justify'
                                                }}
                                            >
                                                Dengan ini saya menyetujui, menyatakan dan menerima beberapa poin berikut ini:
                                            </Typography>
                                            <Box
                                                sx={{
                                                    marginTop: '16px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '16px' // Spasi antar pernyataan
                                                }}
                                            >
                                                {listAgreement && listAgreement.map((data, index) => (
                                                    <Grid
                                                        container
                                                        key={index}
                                                        alignItems="flex-start" // Sejajarkan item ke atas
                                                        spacing={1}
                                                    >
                                                        {/* Kolom untuk nomor */}
                                                        <Grid item xs="auto">
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                                    fontSize: '14px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '22px',
                                                                }}
                                                            >
                                                                {data.number}.
                                                            </Typography>
                                                        </Grid>

                                                        {/* Kolom untuk deskripsi */}
                                                        <Grid item xs>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                                    fontSize: '14px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: '22px',
                                                                    textAlign: 'justify'
                                                                }}
                                                            >
                                                                {data.description}
                                                            </Typography>
                                                        </Grid>

                                                        {/* Kolom untuk checkbox */}
                                                        <Grid item xs="auto">
                                                            <Checkbox
                                                                checked={checkboxValues[index]}
                                                                onChange={() => handleAgreementCheckboxChange(index)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Box>

                                        </Box>

                                        <Box
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            <Divider
                                                variant="string"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    borderBottomWidth: 0.5,
                                                    // marginTop: "2px",
                                                }}
                                            />
                                        </Box>

                                        {/* Signature */}
                                        <Grid
                                            container
                                            // spacing={2}
                                            xs={12}
                                            sm={12}
                                            sx={{
                                                padding: '10px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Grid item xs={12} sm={12}>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                    }}
                                                >
                                                    Unggah Tanda Tangan
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{ marginTop: '5px', }}>
                                                <Box
                                                    sx={{
                                                        maxWidth: isMobile ? '100%' : '286px',
                                                        width: '100%',
                                                        overflow: 'hidden',
                                                        height: '140px',
                                                        backgroundColor: '#fff',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '12px',
                                                        position: 'relative',
                                                        border: `solid 2px ${DividerColor}`,
                                                        borderStyle: 'dashed',
                                                    }}
                                                    onDragOver={(e) => handleDragOver(e, 'user signature')}
                                                    onDrop={(e) => handleDrop(e, 'user signature')}
                                                    onDragLeave={handleDragLeave}
                                                >
                                                    {isDragging.isDraggingDigitalTTD && !isMobile ?
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                fontSize: '13px',
                                                                fontStyle: 'normal',
                                                                fontWeight: 'bold',
                                                                lineHeight: '17,64px',
                                                                textAlign: 'center',
                                                                color: mainColor
                                                            }}
                                                        >
                                                            Lepaskan untuk mengunggah
                                                        </Typography>
                                                        :
                                                        selectedImageTTD && selectedImageTTD.type === 'application/pdf' ? (
                                                            // Handle PDF display logic
                                                            <>
                                                                {handleShowPDF(selectedImageTTD.name)}
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '4px',
                                                                        right: '4px',
                                                                        backgroundColor: '#fff',
                                                                        borderRadius: '50%',
                                                                        padding: '2px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => handleRemoveImage('user signature')}
                                                                >
                                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                                </Box>
                                                            </>
                                                        ) : imageUrlTTD ? (
                                                            // Handle image display logic
                                                            <>
                                                                <img
                                                                    src={imageUrlTTD}
                                                                    alt="selected-image"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'contain',
                                                                        borderRadius: '4px',
                                                                    }}
                                                                />
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '4px',
                                                                        right: '4px',
                                                                        backgroundColor: '#fff',
                                                                        borderRadius: '50%',
                                                                        padding: '2px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => handleRemoveImage('user signature')}
                                                                >
                                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                                </Box>
                                                            </>
                                                        ) : (
                                                            // Display upload options
                                                            <>
                                                                {!isMobile ?
                                                                    <>
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                                fontSize: '13px',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: '17,64px',
                                                                                textAlign: 'center'
                                                                            }}
                                                                        >
                                                                            {imageUrlTTD ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{
                                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                                fontSize: '11px',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: '17,64px',
                                                                                color: TextGray
                                                                            }}
                                                                        >
                                                                            atau
                                                                        </Typography>
                                                                    </>
                                                                    :
                                                                    undefined
                                                                }
                                                                <div
                                                                    onClick={() => handleImageUpload(fileInputRefTTD)}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        padding: '6px 12px',
                                                                        border: '1px solid #ccc',
                                                                        borderRadius: '4px',
                                                                        display: 'inline-block',
                                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                                        fontSize: '12px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        lineHeight: '17,64px',
                                                                        backgroundColor: bgColor
                                                                    }}
                                                                >
                                                                    Pilih {imageUrlTTD ? 'Gambar' : 'Gambar/PDF'}
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    <input
                                                        type="file"
                                                        accept="image/*,.pdf"  // Accept both image and PDF files
                                                        onChange={(e) => handleFileInputChange(e.target.files[0], 'user signature')}
                                                        ref={fileInputRefTTD}
                                                        style={{ display: 'none' }}
                                                    />
                                                </Box>
                                            </Grid>

                                            {/* <Grid item xs={12} sm={4} sx={{ margin: isMobile ? '50px 0px 10px 0px' : undefined }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: '5px'
                                    }}
                                >
                                    <QRCode
                                        value='testing'
                                        size={200}
                                        style={{
                                            height: "95px",
                                            maxWidth: "95px",
                                            width: "95px",
                                            marginBottom: '5px'
                                            // padding: '10px',
                                            // border: 'solid 2px #D9D9D9',
                                            // backgroundColor: brandColor,
                                            // borderRadius: '10px',
                                        }}
                                        viewBox={`0 0 256 256`}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            color: Dark,
                                        }}
                                    >
                                        Darrent Watts
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            color: Dark
                                        }}
                                    >
                                        {moment(new Date()).format('DD-MMMM-YYYY')}
                                    </Typography>
                                </Box>
                            </Grid> */}
                                        </Grid>

                                        <Box
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            <Divider
                                                variant="string"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    borderBottomWidth: 0.5,
                                                    // marginTop: "2px",
                                                }}
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Checkbox
                                                checked={finalAgreementChecked} // Status checkbox akhir
                                                onChange={handleFinalCheckboxChange} // Toggle checkbox akhir
                                                disabled={!checkboxValues.every((checked) => checked)} // Disable jika semua checkbox tidak dicentang
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '22px',
                                                    // marginBottom: '5px',
                                                    textAlign: 'justify',
                                                }}
                                            >
                                                Saya setuju dengan semua pernyataan diatas.
                                            </Typography>
                                        </Box>

                                    </Card>
                                </Grid>

                                <Grid item sx={12} md={12}>
                                    <Box
                                        align='center'
                                        sx={{
                                            width: '100%',
                                            marginTop: '26px',
                                            marginBottom: '56px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            // backgroundColor:'orange',
                                        }}
                                    >
                                        <Button
                                            // fullWidth
                                            variant='contained'
                                            size='medium'
                                            type='submit'
                                            sx={{
                                                minWidth: '350px',
                                                backgroundColor: btnBgColor,
                                                color: mainColor,
                                                padding: '10px 56px',
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    color: 'white', // Change text color to white on hover
                                                    backgroundColor: mainColor,
                                                },
                                            }}
                                            disabled={loadingSimpanData ? true : false}
                                        >
                                            {loadingSimpanData ?
                                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                                :
                                                'Simpan Data'
                                            }
                                        </Button>
                                    </Box>
                                </Grid>
                                {/* <LocationDetectorModal
                                open={openLocationDetectorModal}
                                close={() => setOpenLocationDetectorModal(false)}
                                askForLocationPermission={() => askForLocationPermission()}
                                location={location}
                                isLoading={isButtonPermissionLoading}
                            /> */}
                                {/* <VerificationDataModal
                                open={openVerificationDataModal}
                                openModal={() => setOpenVerificationDataModal(true)}
                                close={() => setOpenVerificationDataModal(false)}
                                notificationStatus={() => setNotificationSuccessRegister('Y')}
                                isPageLoading={isPageLoading}
                                setPageLoadingTrue={() => setIsPageLoading(true)}
                                setPageLoadingFalse={() => setIsPageLoading(false)}
                                newData={newData}
                                selectedBukuTabungan={selectedBukuTabungan}
                                imageUrlBukuTabungan={imageUrlBukuTabungan}
                                answer={answer}
                                answer2={answer2}
                                selectedImageTTD={selectedImageTTD}
                                imageUrlTTD={imageUrlTTD}
                                checkboxValues={checkboxValues}
                                dataUsers={dataUsers}
                            /> */}
                                {isPageLoading ?
                                    <LoadingData
                                        open={isPageLoading}
                                    // close={}
                                    />
                                    :
                                    undefined
                                }
                            </form>

                            :
                            <Box
                                align='center'
                                sx={{
                                    margin: '56px 0px',
                                    overflow: 'hidden',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '24px',
                                        maxWidth: isMobile ? '100%' : '800px',
                                        // backgroundColor:'orange',
                                        width: '100%'
                                    }}
                                >
                                    <img
                                        src={IconCheck}
                                        alt='logo-email'
                                        style={{
                                            width: '100px',
                                            height: '100px'
                                        }}
                                    />

                                    <Box
                                        sx={{
                                            padding: '10px',
                                            borderRadius: '8px',
                                            background: '#F8D70033',
                                            maxWidth: isMobile ? '100%' : '700px',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: isMobile ? '14px' : '18px',
                                                fontWeight: '500',
                                                lineHeight: '24px',
                                                textAlign: 'left',
                                                justifyContent: 'justify'
                                            }}
                                        >
                                            Pendaftaran beasiswa berhasil, berkas telah tersimpan disistem dan akan diverifikasi oleh tim administrasi.
                                        </Typography>
                                        {<br></br>}
                                        <Typography
                                            sx={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: isMobile ? '14px' : '18px',
                                                // fontStyle:'italic',
                                                fontWeight: '500',
                                                lineHeight: '24px',
                                                textAlign: 'left',
                                                justifyContent: 'justify'
                                            }}
                                        >
                                            Hasil verifikasi akan dikirimkan ke email {' '}
                                            <span style={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: isMobile ? '14px' : '18px',
                                                fontStyle: 'italic',
                                                fontWeight: 'bold',
                                                lineHeight: '24px',
                                                color: mainColor
                                            }}>
                                                {emailUser}
                                            </span>
                                            {' '}
                                            dalam kurun waktu 6x24 jam, pastikan untuk memeriksa email secara berkala.
                                        </Typography>
                                    </Box>
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        size='medium'
                                        onClick={handleSubmitSuccessRegistered}
                                        sx={{
                                            backgroundColor: btnBgColor,
                                            color: mainColor,
                                            width: isMobile ? '100%' : '50%',
                                            textTransform: 'none',
                                            fontWeight: 'bold',
                                            fontSize: isMobile ? '14px' : '16px',
                                            marginTop: '30px',
                                            '&:hover': {
                                                color: 'white', // Change text color to white on hover
                                                backgroundColor: mainColor,
                                            },
                                        }}
                                    >
                                        {loadingKonfirmasiPendaftaran ?
                                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                            :
                                            'Login'
                                        }
                                    </Button>
                                </Box>

                            </Box>
                        }
                    </Box>
                </Grid>
                <ToastContainer style={{ width: '100%', maxWidth: isMobile ? '100%' : '350px' }} />
            </Grid>
            <Footer />
        </>
    )
}

export default RegistrasiTahap3